.XapienLogo_xpl721s{width:48px;height:48px;}.XapienLogo_xpl721s path{fill:#28effa;}
.Container_c1yeol7e{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.Inputs_i1uxzwx7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;width:100%;}.Inputs_i1uxzwx7 input{width:100%;}
.Title_tlwfxkn{color:#02a3d5;}
.Subtitle_s83h3qn{color:#ffffff;font-size:1.5rem;text-align:center;}
.DownloadOptionContainer_d4fhkpg{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.DownloadOption_d1wmxyv3{width:100%;background:#244F6B;border-radius:9px;padding:10px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:10px;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;color:#ffffff;-webkit-text-decoration:none;text-decoration:none;}.DownloadOption_d1wmxyv3 img{width:32px;height:32px;}
.DownloadLink_d1m846yi{color:#28effa;-webkit-text-decoration:none;text-decoration:none;}
.AuthenticationApps_afl3x3e{padding:1rem 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;width:100%;}
.Description_d7v5dt6{margin:0;text-align:center;color:#b3b5bd;font-size:0.875rem;}
.QRCodeContainer_q1xspydl{background:white;padding:1rem;border-radius:1rem;}
.ErrorBanner_e134ncyn{-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
